<template>
  <v-card>
    <v-card-title>
      Materialer

      <v-spacer></v-spacer>

      <v-fade-transition>
        <div v-if="selectedParts.length" class="d-flex align-center">
          <div class="pr-2 text-caption">
            {{ selectedParts.length }} {{ selectedParts.length > 1 ? 'varer' : 'vare' }}
          </div>
          <v-btn
            text
            small
            @click="deleteParts">
            <v-icon small>
              mdi-delete-outline
            </v-icon>
          </v-btn>
          <v-menu
            v-model="customGroupMenu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
            min-width="500"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
              >
                Ret gruppe
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <v-text-field
                  v-model="customGroupInput"
                  label="Gruppenavn"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="customGroupMenu = false">
                  Annuller
                </v-btn>

                <v-spacer />

                <v-btn text @click="saveCustomGroups">
                  Gem
                  <v-icon right> mdi-content-save-outline </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-fade-transition>

      <template v-if="section">
        <SpecificationSectionPartCreate
          v-bind="section"
          @create:success="onPartCreateSuccess"
        />
        <SpecificationSectionPartAdd
          v-bind="section"
          @create:success="onPartCreateSuccess"
        />
      </template>
    </v-card-title>

    <v-data-table
      :headers="partHeaders"
      :items="items"
      v-model="selectedParts"
      :show-select="!!section"
      :group-by="hasCustomGroups ? 'customGroup' : null"
      show-group-by
    >
      <template #[`item.title`]="{ item }">
        {{ item.title }}
      </template>
      <template #[`item.quantity`]="{ item }">
        <v-edit-dialog
          v-if="!$attrs.locked"
          :return-value.sync="item.quantity"
          @save="$emit('update')"
        >
          {{ item.quantity | formatNumberFixed2 }}

          <template #input>
            <v-text-field
              v-model.number="item.quantity"
              :rules="[v => typeof v === 'number' || 'Skal være et tal']"
              label="Rediger"
              single-line
            />
          </template>
        </v-edit-dialog>
        <div v-else>
          {{ item.quantity | formatNumberFixed2 }}
        </div>
      </template>
      <template #[`item.costPrice`]="{ item }">
        {{ item.costPrice | formatNumberFixed2 }}
      </template>
      <template #[`item.price`]="{ item }">
        {{ item.price | formatNumberFixed2 }}
      </template>
      <template #[`item.totalQuantity`]="{ item }">
        {{ item.totalQuantity | formatNumberFixed2 }}
      </template>
      <template #[`item.totalCostPrice`]="{ item }">
        {{ item.totalQuantity * item.costPrice | formatNumberFixed2 }}
      </template>
      <template #[`item.totalPrice`]="{ item }">
        {{ item.totalQuantity * item.price | formatNumberFixed2 }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SpecificationSectionPartCreate from '@/components/specification/SpecificationSectionPartCreate.vue';
import SpecificationSectionPartAdd from '@/components/specification/SpecificationSectionPartAdd.vue';

export default {
  name: 'SpecificationMaterialTable',
  components: {
    SpecificationSectionPartCreate,
    SpecificationSectionPartAdd
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    section: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedParts: [],
      customGroupInput: null,
      customGroupMenu: false
    }
  },
  computed: {
    partHeaders() {
      const headers = [
        { text: 'Varenummer', value: 'part_PartNumber', width: '10%', groupable: false },
        { text: 'EAN', value: 'part_EAN', width: '10%', groupable: false },
        { text: 'Navn', value: 'title', width: '40%', groupable: false },
        { text: 'Gruppe', value: 'customGroup' },
        { text: 'Antal', value: 'quantity', align: 'end', groupable: false },
        { text: 'Kost pr. enhed', value: 'costPrice', align: 'end', groupable: false },
        { text: 'Salg pr. enhed', value: 'price', align: 'end', groupable: false },
        { text: 'Total kost', value: 'totalCostPrice', align: 'end', groupable: false },
        { text: 'Total salg', value: 'totalPrice', align: 'end', groupable: false }
      ];

      if (!!this.section) {
        headers.splice(6, 0, { text: 'Total antal', value: 'totalQuantity', align: 'end', groupable: false });
      }

      return headers;
    },
    hasCustomGroups() {
      return this.items?.some(item => !!item.customGroup)
    }
  },
  methods: {
    onPartCreateSuccess: function(part) {
      this.section.parts.push(part);
    },
    deleteParts: async function() {
      const ids = this.selectedParts.map(x => x.id);

      if (!ids.length) return;

      try {
        this.deleting = true;

        // Create promises
        const promises = this.selectedParts.map(x => {
          return new Promise((resolve, reject) => {
            this.$AuthService.api.delete(`specification/section/part/${x.id}`)
            .then(() => {
              const index = this.section.parts.findIndex(part => part.id === x.id);
              this.section.parts.splice(index, 1);
              resolve();
            })
            .catch(error => { reject(error) });
          });
        });

        await Promise.all(promises);
        
        this.$notifier.showSuccess({ message: 'Materialer slettet' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'En eller flere materialer kunne ikke slettes' });
      } finally {
        this.deleting = false;
      }
    },

    saveCustomGroups: function() {
      this.selectedParts.forEach((part) => part.customGroup = this.customGroupInput);

      this.$emit('update');
      this.selectedParts = [];
      this.customGroupInput = null;
      this.customGroupMenu = false;
    }
  }
}
</script>
