<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    origin="right top"
    left
    min-width="1000"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="!spec"
        text
        small
      >
        Tilføj vare
        <v-icon right> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Tilføj vare
        </v-toolbar-title>

        <v-spacer />

        <div
          class="d-flex align-center"
          style="gap: 1rem"
        >
          <v-switch
            v-model="onlyFavorites"
            label="Vis kun TPA favoritter"
            hide-details
          />

          <v-switch
            v-model="onlyPreferred"
            label="Vis kun foretrukket af kunde"
            hide-details
          />

          <v-btn
            icon
            @click="close"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col>
              <v-text-field
                v-model="searchInput"
                :loading="loading"
                solo
                autofocus
                label="Søg efter vare"
                @submit.prevent
                @keydown.enter.prevent="search"
              >
                <template #append>
                  <v-btn
                    icon
                    @click="search"
                  >
                    <v-icon>
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-slide-y-transition>
          <div
            v-if="loading || parts.length"
            class="pr-5"
            style="max-height: 800px; overflow: hidden auto;"
          >
            <v-card
              v-for="part of parts"
              :key="part.id"
              width="100%"
              class="ma-2"
              @click="onPartSelect(part)"
            >
              <v-card-title>
                <div>{{ part.title }}</div>
              </v-card-title>

              <v-card-subtitle
                v-if="part.customers?.some(c => c.isFavorite || c.isPreferredByCustomer)"
              >
                <span
                  v-for="customer of part.customers"
                  :key="customer.id"
                >
                  <v-chip
                    v-if="customer.isFavorite"
                    small
                    class="mr-2"
                  >
                    TPA favorit for {{ customer.title }}
                    <v-icon
                      small
                      right
                      color="yellow"
                    >
                      mdi-star
                    </v-icon>
                  </v-chip>

                  <v-chip
                    v-if="customer.isPreferredByCustomer"
                    small
                  >
                    Foretrukket af {{ customer.title }}
                    <v-icon
                      small
                      right
                      color="yellow"
                    >
                      mdi-star
                    </v-icon>
                  </v-chip>
                </span>
              </v-card-subtitle>

              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    <v-lazy>
                      <v-img
                        :src="part.imageUrl"
                        :lazy-src="require('@/assets/missing-image.png')"
                        height="150"
                        contain
                      ></v-img>
                    </v-lazy>
                  </v-col>
                  <v-col>
                    <v-simple-table
                      dense
                    >
                      <template #default>
                        <tbody>
                          <tr>
                            <td>
                              EAN
                            </td>
                            <td class="text-right">
                              {{ part.ean || '-' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Typebetegnelse
                            </td>
                            <td class="text-right">
                              {{ part.manufacturerTypeReference || '-' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Kundevarenummer
                            </td>
                            <td class="text-right">
                              {{ getPartCustomerDetail(part)?.partNumber || '-' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Kostpris
                            </td>
                            <td class="text-right">
                              {{ part.costPrice | formatNumberFixed2 }}
                            </td>
                          </tr>
                          <tr
                            v-for="customer of part.customers"
                            :key="customer.id"
                          >
                            <td>
                              Kundepris ({{ customer.title }})
                            </td>
                            <td class="text-right">
                              {{ customer.price | formatNumberFixed2 }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-slide-y-transition>

        <v-fade-transition>
          <v-card
            v-if="hasSearched && !parts.length"
            flat
            disabled
          >
            <v-card-text class="d-flex justify-center py-8">
              Ingen resultater
            </v-card-text>
          </v-card>
        </v-fade-transition>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close">
          Annuller
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SpecificationSectionPartAdd',
  props: [
    'id',
  ],
  data() {
    return {
      menu: false,
      valid: false,
      creating: false,
      loading: false,
      hasSearched: false,
      onlyFavorites: false,
      onlyPreferred: false,
      part: null,
      parts: [],
      searchInput: ''
    }
  },
  computed: {
    ...mapState({
      spec: state => state.specification.currentSpecification
    })
  },
  methods: {
    close() {
      this.menu = false;
      this.searchInput = '';
      this.hasSearched = false;
      this.part = null;
      this.parts = [];

      this.$refs.form.reset();
    },
    async add(part) {
      if (!this.$refs.form.validate()) return;

      const { id, title, description, costPrice } = part;

      const data = {
        specificationSection_Id: this.id,
        part_Id: id ?? null,
        title,
        description,
        quantity: 1,
        costPrice,
        price: this.getPartCustomerDetail(part).price
      };

      try {
        this.creating = true;

        const response = await this.$AuthService.api.post('specification/section/part', data);

        if (!response.data.success) throw 'Error creating section part';

        this.$emit('create:success', response.data.model);
        this.$notifier.showSuccess({ message: 'Materiale tilføjet' });
        this.close();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    },
    async search() {
      const text = this.searchInput;
      this.parts = [];

      try {
        this.loading = true;
        
        const response = await this.$AuthService.api.post('adminpart/listsearch', {
          customerId: this.spec.relationCustomerId,
          favoritesOnly: this.onlyFavorites,
          isPreferredByCustomerOnly: this.onlyPreferred,
          page: 1,
          pageSize: 10,
          textSearch: text
        });

        this.parts = response.data.data?.parts;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.loading = false;
        this.hasSearched = true;
      }
    },

    getPartCustomerDetail (part) {
      return part.customers.find(customerDetail => customerDetail.title === this.spec.relationCustomerTitle);
    },

    onPartSelect (part) {
      this.add(part).then(() => {
        this.close();
      });
    }
  },
  watch: {
    part(value) {
      this.title = value.title;
      this.description = value.description;
      this.costPrice = value.costPrice;
      this.price = value.customers[0].price;
    }
  }
}
</script>