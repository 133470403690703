<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    origin="right top"
    min-width="500"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        small
      >
        Specialvare
        <v-icon right> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Specialvare
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="title"
            label="Titel"
          />
          <v-text-field
            v-model="description"
            label="Beskrivelse"
          />
          <v-text-field
            v-model="quantity"
            type="number"
            label="Antal"
            :rules="[v => !!v || 'Udfyld antal']"
          />
          <v-text-field
            v-model="costPrice"
            type="number"
            label="Kostpris"
          />
          <v-text-field
            v-model="price"
            type="number"
            label="Pris"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close">
          Annuller
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="add">
          Tilføj
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SpecificationSectionPartCreate',
  props: ['id'],
  data() {
    return {
      menu: false,
      valid: false,
      creating: false,
      title: '',
      description: '',
      quantity: null,
      costPrice: null,
      price: null,
    }
  },
  methods: {
    close() {
      this.menu = false;
      this.ean = '';
      this.title = '';
      this.description = '';
      this.quantity = null;
      this.costPrice = null;
      this.price = null;

      this.$refs.form.reset();
    },
    async add() {
      if (!this.$refs.form.validate()) return;

      const { title, description, quantity, costPrice, price } = this;

      const part = {
        specificationSection_Id: this.id,
        part_Id: this.part?.id ?? null,
        title,
        description,
        quantity,
        costPrice,
        price
      };

      try {
        this.creating = true;

        const response = await this.$AuthService.api.post('specification/section/part', part);

        if (!response.data.success) throw 'Error creating section part';

        this.$emit('create:success', response.data.model);
        this.$notifier.showSuccess({ message: 'Materiale tilføjet' });
        this.close();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    },
  }
}
</script>