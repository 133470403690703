<template>
  <v-container>
    <v-toolbar flat color="transparent">
      <v-toolbar-title>
        Bestilling {{ order.orderNumber }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        text
        :laoding="deleting"
        class="mr-3"
        @click="deleteOrder"
      >
        Slet
        <v-icon right> mdi-delete-outline </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        :laoding="saving"
        @click="update"
      >
        Opdater
        <v-icon right> mdi-content-save-outline </v-icon>
      </v-btn>
    </v-toolbar>

    <v-row>
      <v-col>
        <v-card>
          <v-form ref="form" v-model="valid">
            <v-card-text>
              <v-textarea
                v-model="order.note"
                label="Note"
              />
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Varelinjer
          </v-card-title>
          <v-card-text>
            <SpecificationMaterialTable :items="order.lines" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpecificationMaterialTable from '@/components/specification/SpecificationMaterialTable'

export default {
  name: 'SupplierOrderEdit',
  components: {
    SpecificationMaterialTable
  },
  data() {
    return {
      deleting: false,
      loading: false,
      order: {},
      saving: false,
      valid: false
    }
  },
  async mounted() {
    const id = this.$route.params.id;

    try {
      this.loading = true;

      const order = await this.$AuthService.api.get(`supplier-orders/${id}`);

      this.order = order.data;
    } catch (error) {
      console.error(error);
      this.$notifier.showError({});
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async update() {
      const id = this.$route.params.id;

      try {
        this.saving = true;

        const response = await this.$AuthService.api.put(`supplier-orders/${id}`, this.order);

        this.order = response.data;

        this.$notifier.showSuccess({ message: 'Ændringerne blev gemt' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.saving = false;
      }
    },
    async deleteOrder() {
      const id = this.$route.params.id;

      try {
        this.deleting = true;

        const response = await this.$AuthService.api.delete(`supplier-orders/${id}`);

        this.order = response.data;

        this.$notifier.showSuccess({ message: 'Ændringerne blev gemt' });
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.deleting = false;
      }
    }
  }
}
</script>